
<template>
    <div class="stepsdemo-content">
        <Card class="py-10">
            <template v-slot:title>
              <h2 class="text-center mx-auto w-full lg:w-3/4 xl:w-1/2">
                Detallado del Proceso
              </h2>
            </template>
            <template v-slot:content>
              <div class="w-full p-2 mx-auto lg:w-3/4 xl:w-1/2 flex justify-center items-center">
                <div>
                  <div class="flex gap-6 px-2 mb-4">
                    <div class="field-radiobutton">
                      <RadioButton name="city" value="1" v-model="model.tipo" />
                      <label>Derecho de petición</label>
                    </div>
                    <div class="field-radiobutton">
                      <RadioButton name="city" value="2" v-model="model.tipo" />
                      <label>Requerimiento</label>
                    </div>
                    <div class="field-radiobutton">
                      <RadioButton name="city" value="3" v-model="model.tipo" />
                      <label>PQR</label>
                    </div>
                  </div>
                  <div class="px-2">
                    <label class="font-medium text-sm">Asunto</label>
                    <div class="mb-4">
                      <InputText
                        v-model="model.asunto"
                        placeholder="Asunto"
                        class="w-full uppercase"/>
                      <MessageError :text="errors.asunto"/>
                    </div>
                  </div>
                  <div class="flex gap-4 px-2">
                    <div class="mb-4">
                      <label class="font-medium text-sm">Fecha de notificacion</label>
                      <input class="p-inputtext w-full"
                            type="datetime-local"
                            v-model="model.fechaNotificacion"
                      >
                      <MessageError :text="errors.fechaNotificacion"/>
                    </div>
                    <div class="mb-4">
                      <label class="font-medium text-sm">Fecha de vencimiento</label>
                      <input class="p-inputtext w-full"
                            type="datetime-local"
                            v-model="model.fechaVencimiento"
                      >
                      <MessageError :text="errors.fechaVencimiento"/>
                    </div>
                  </div>
                  <div class="px-2 w-full">
                    <div style="max-height: 150px; overflow-y: auto;">
                      <div class="flex justify-between" v-for="(item, key) in paths" :key="key">
                        <div class="flex mt-2">
                          <i class="pi pi-file-pdf mr-2 text-black"></i>
                          <p>{{ item.fileName }}</p>
                        </div>
                        <div>
                          <button><i class="pi pi-trash mr-2 text-red-600"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="px-2 mt-4 w-full flex flex-col">
                    <button label="Show" icon="pi pi-external-link" @click="openMaximizable" class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
                      Agregar nuevo soporte
                    </button>
                    <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
                        <div>
                          <div>
                            <label class="text-sm" for="titulo">Titulo</label>
                            <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
                            class="text-sm mt-2 uppercase block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          </div>
                          <div class="my-2">
                            <label class="text-sm">Adjunte el documento</label>
                            <div class="mt-2">
                              <button class="w-full">
                                <FilePond ref="pond"/>
                              </button>
                            </div>
                          </div>
                        </div>
                        <template class="text-center" #footer>
                            <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
                        </template>
                    </Dialog>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <div class="w-full p-2 mx-auto lg:w-3/4 xl:w-1/2 flex justify-center items-center">
                <div class="flex w-full justify-center md:justify-between px-2">
                    <Button label="Regresar" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Guardar" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
              </div>
            </template>
        </Card>
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import storeDerechoPeticion from '../../../../../store/derechosPeticion.store'
import StoreUsuarios from '@/apps/pharmasan/juridica/procesosJuridicos/store/usuarios.store'
import { useRouter } from 'vue-router'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import { useToast } from 'primevue/usetoast'
import filepondStore from '../../../../../store/filepond.store'
import FilePond from '../../../../uploadFilePoundNew/index.vue'
export default {
  name: 'DatosDerecho',
  components: {
    FilePond
  },
  setup () {
    const router = useRouter()
    const toast = useToast()
    /** state **/
    const schema = yup.object({
      asunto: yup.string().nullable().required('El campo es obligatorio').label(''),
      tipo: yup.number().nullable().required('El campo es obligatorio').label(''),
      fechaNotificacion: yup.string().nullable().required('El campo es obligatorio').label(''),
      fechaVencimiento: yup.string().nullable().required('El campo es obligatorio').label(''),
      paths: yup.array().label('')
    })
    const { errors, values: model, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    useField('fechaNotificacion', null, {
      initialValue: null
    })
    useField('fechaVencimiento', null, {
      initialValue: null
    })
    useField('asunto', null, {
      initialValue: null
    })
    useField('paths')
    useField('tipo')
    /** ref **/
    const paths = ref([])
    const title = ref('')
    const pond = ref()
    const displayMaximizable = ref(false)
    /** data **/
    /** computed **/
    const adjunto = computed(() => filepondStore.getters.adjunto)
    const derecho = computed(() => storeDerechoPeticion.getters._derecho)
    /** methodos **/
    const nextPage = handleSubmit((values) => {
      storeDerechoPeticion.commit('setDetalleDerecho', values)
      storeDerechoPeticion.dispatch('createDerecho').then(() => {
        toast.add({ severity: 'success', summary: 'Exíto', detail: 'registro exitoso', life: 3000 })
        router.push({ name: 'pharmasan.administrativa.juridica.procesos-administrativos.listado' })
        handleReset()
      })
    })
    const prevPage = () => {
      router.push('datos-persona', { pageIndex: 0 })
    }
    const openMaximizable = () => {
      displayMaximizable.value = true
    }
    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        paths.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        displayMaximizable.value = false
        title.value = ''
      })
      model.paths = paths.value
    }
    const resetDerecho = () => {
      handleReset()
      storeDerechoPeticion.commit('setReset')
      router.push({ name: 'pharmasan.administrativa.juridica.procesos-administrativos' })
    }
    /** hook **/
    onMounted(() => {
      StoreUsuarios.dispatch('getAll')
    })
    return {
      derecho,
      errors,
      model,
      resetDerecho,
      nextPage,
      prevPage,
      openMaximizable,
      closeMaximizable,
      displayMaximizable,
      title,
      pond,
      paths
    }
  }
  }
</script>
<style lang="scss" scoped>
 .j-label{
   color: #354357;
   font-size: 16px;
   opacity: 0.83;
 }
 .actual-btn {
   background-color: #1E40AF;
   color: white;
   padding: 0.5rem;
   font-family: sans-serif;
   border-radius: 0.3rem;
   cursor: pointer;
   width: 130px;
   //margin-top: 1rem;
 }
</style>
